import { createContext, useContext, useState } from "react";

const FileUploadContext = createContext<{
  files: File[];
  addFiles: (files: File[]) => void;
  removeFiles: (files: File[]) => void;
  clearFiles: () => void;
} | null>(null);

export const FileUploadProvider = ({ children }: { children: React.ReactNode }) => {
  const [files, setFiles] = useState<File[]>([]);

  const addFiles = (newFiles: File[]) => {
    setFiles([...files, ...newFiles]);
  };

  const removeFiles = (filesToRemove: File[]) => {
    setFiles(files.filter(file => !filesToRemove.includes(file)));
  };

  const clearFiles = () => {
    setFiles([]);
  };

  return (
    <FileUploadContext.Provider value={{ files, addFiles, removeFiles, clearFiles }}>
      {children}
    </FileUploadContext.Provider>
  );
};

export const useFileUploadContext = () => {
  const context = useContext(FileUploadContext);

  if (!context) {
    throw new Error("useFileUploadContext must be used within a FileUploadProvider");
  }

  return context;
};
